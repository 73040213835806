import testimaonial from "../Assets/image 2.svg";
import feedback1 from "../Assets/feedback1.png";
export const quizData = [
  { question: "1You Would Enjoy Working With Your Hands And Tool" },
  { question: "2You Would Enjoy Working With Your Hands And Tool" },
  { question: "3You Would Enjoy Working With Your Hands And Tool" },
  { question: "4You Would Enjoy Working With Your Hands And Tool" },
  { question: "5You Would Enjoy Working With Your Hands And Tool" },
  { question: "6You Would Enjoy Working With Your Hands And Tool" },
  { question: "7You Would Enjoy Working With Your Hands And Tool" },
  { question: "8You Would Enjoy Working With Your Hands And Tool" },
  { question: "9You Would Enjoy Working With Your Hands And Tool" },
  { question: "10You Would Enjoy Working With Your Hands And Tool" },
  // {question:"11You Would Enjoy Working With Your Hands And Tool"},
  // {question:"12You Would Enjoy Working With Your Hands And Tool"},
  // {question:"13You Would Enjoy Working With Your Hands And Tool"},
  // {question:"14You Would Enjoy Working With Your Hands And Tool"},
  // {question:"15You Would Enjoy Working With Your Hands And Tool"},
  // {question:"16You Would Enjoy Working With Your Hands And Tool"},
  // {question:"17You Would Enjoy Working With Your Hands And Tool"},
  // {question:"18You Would Enjoy Working With Your Hands And Tool"},
  // {question:"19You Would Enjoy Working With Your Hands And Tool"},
  // {question:"20You Would Enjoy Working With Your Hands And Tool"},
  // {question:"15You Would Enjoy Working With Your Hands And Tool"},
  // {question:"16You Would Enjoy Working With Your Hands And Tool"},
  // {question:"17You Would Enjoy Working With Your Hands And Tool"},
  // {question:"18You Would Enjoy Working With Your Hands And Tool"},
  // {question:"19You Would Enjoy Working With Your Hands And Tool"},
  // {question:"25You Would Enjoy Working With Your Hands And Tool"},
];

export const answerRound = [{ className: "filled" }];

export const reviewsData = [
  {
    id: 1,
    name: "Muahmmed Danis",
    designation: "ARCHITECT(INTJ-A)",
    image: feedback1,
    alt: "image",
    review:
      "incredible through and scary.like someone putting a mirror to yout face and seeing your true self whether you like it or not",
    rating: "4.3",
  },
  {
    id: 2,
    name: "Mohammed Rinshad",
    designation: "DOCTOR(INFJ-T)",
    image: feedback1,
    alt: "image",
    review:
      "Wow ! this site is just AMAZING ! took the test and the results were so spot on,i felt like i had been vindicated ,like someone finally gets me and iam werido",
    rating: "4.6",
  },
  {
    id: 3,
    name: "Sreehari",
    designation: "DEFENDER(ISFJ-A)",
    image: feedback1,
    alt: "image",
    review:
      "Reding this profile is comferting ,exciting inspiring , and a little bit freaky:)its great to have my strength and weakness so clearly articulated",
    rating: "4.8",
  },
  {
    id: 4,
    name: "Hiba Sherin",
    designation: "COMMANDER(ENTJ-A)",
    image: feedback1,
    alt: "image",
    review:
      "it is awsome to read somthing that describes you so well ...and knowing you are not a weirdo ! i did not expect a profile so accurate",
    rating: "4.5",
  },
  {
    id: 5,
    name: "Saleem",
    designation: "ARCHITECT(INTJ-A)",
    image: feedback1,
    alt: "image",
    review:
      "Everything just fell into place , i could breath freely,as if my body and mind were unshackled, i found a part of myself that i had lost years prior:my passion",
    rating: "4",
  },
  {
    id: 6,
    name: "Mohammed Shan",
    designation: "ENGINEER(INFJ-T)",
    image: feedback1,
    alt: "image",
    review:
      "i was really pleased reading my proifle,what stated as fun online test,finished as a deep and insightful look at my everyday life,work and relationships",
    rating: "4.7",
  },
];

export const PolarAngleAxisData = [
  {
    line_x1: "300",
    line_y1: "100",
    line_x2: "300",
    line_y2: "92",
    text_x: "300",
    text_y: "92",
    text_anchor: "middle",
    tspan_x: "300",
    tspan_dy: "0em",
    axis_name: "Investigative",
  },
  {
    line_x1: "429.9038105676658",
    line_y1: "175",
    line_x2: "436.83201379794133",
    line_y2: "172",
    text_x: "436.83201379794133",
    text_y: "171",
    text_anchor: "start",
    tspan_x: "436.83201379794133",
    tspan_dy: "0em",
    axis_name: "Realistic",
  },
  {
    line_x1: "429.9038105676658",
    line_y1: "325",
    line_x2: "436.83201379794133",
    line_y2: "329",
    text_x: "436.83201379794133",
    text_y: "345",
    text_anchor: "start",
    tspan_x: "436.83201379794133",
    tspan_dy: "0em",
    axis_name: "Artistic",
  },
  {
    line_x1: "300",
    line_y1: "400",
    line_x2: "300",
    line_y2: "408",
    text_x: "300",
    text_y: "430",
    text_anchor: "middle",
    tspan_x: "300",
    tspan_dy: "0em",
    axis_name: "Conventional",
  },
  {
    line_x1: "170.0961894323342",
    line_y1: "325",
    line_x2: "163.16798620205867",
    line_y2: "329",
    text_x: "163.16798620205867",
    text_y: "345",
    text_anchor: "end",
    tspan_x: "163.16798620205867 ",
    tspan_dy: "0em",
    axis_name: "Enterprising",
  },
  {
    line_x1: "170.0961894323342",
    line_y1: "175",
    line_x2: "163.1679862020587",
    line_y2: "171",
    text_x: "163.1679862020587",
    text_y: "171",
    text_anchor: "end",
    tspan_x: "163.1679862020587",
    tspan_dy: "0em",
    axis_name: "Social",
  },
];

export const chatbotFAQ = [
  {
    id: 1,
    text: "Are you experiencing any issues while making the payment?",
    answer:
      "Please check if your bank's server is currently busy. If the issue persists, please raise a support ticket.",
  },
  {
    id: 2,
    text: "Are you having trouble submitting your answers?",
    answer:
      "please check your internet connectivity. A stable connection is required to complete the submission. If the issue continues, Kindly create a support ticket",
  },
  {
    id: 3,
    text: "Do you need help understanding what your results mean?",
    answer:
      "please choose your preferred language from the language options at the top of the page. If you still have questions, feel free to contact our support team for further assistance.",
  },
];

export const FAQs = [
  {
    id: 1,
    question: "Is there any further detailed result explanation facility ?",
    answer:
      "Our platform provides a detailed result explanation facility, offering insights into your strengths, weaknesses, workplace habits, and personalized career path guidance, helping you understand your performance and make informed career decisions.",
  },
  {
    id: 2 ,
    question: "How can I take the test ?",
    answer:
      "To take the test, first, register by signing up or logging into your account. Then, click the 'Take Test Now' button. After that, complete the payment process to unlock the test. Once the payment is done, you can begin the test by answering the questions carefully and thoughtfully. After finishing all the questions, submit the test. Finally, you can review your detailed results and analysis once the test has been submitted. This ensures a smooth testing experience.",
  },
  {
    id: 3 ,
    question: "How long does it take ?",
    answer:
      "The test usually takes up to 15 minutes for a calm and focused individual, though the exact time may vary based on your pace. Ensure you're in a quiet environment to stay focused and complete the test efficiently.",
  },
  {
    id: 4 ,
    question: "What languages are available for the test ?",
    answer:
      "The test is available in multiple languages, including English, Malayalam, and Arabic, to ensure accessibility for users from different regions. You can select your preferred language before starting the test.",
  },
  {
    id: 5 ,
    question: "Why did I get a different result when I retook the personality test ?",
    answer:
      "We regularly refine our test questions and improve the algorithm's accuracy, which may lead to slight changes in your personality type if your original scores were borderline (e.g., below 55%). External factors like mood, energy levels, and mental state can also influence your responses, leading to varying results.",
  },
  {
    id: 6 ,
    question: "How should I answer the personality test questions for the most accurate result ?",
    answer:
      "For the most accurate result, answer based on how you've typically responded to situations throughout your life, not just recent behavior or idealized versions of yourself. It's best to take the test when you're well-rested and feeling neutral, as mood and energy levels can affect your responses.",
  },
];
