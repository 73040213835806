import { atom, selector } from "recoil";
import config from '../config.json';
import Cookies from 'js-cookie';

export const selectedLang = atom({
  key: 'selectedLang',
  default: { name: 'English', code: 'en' },
});

export const testResult = atom({
  key: 'testResult',
  default: selector({
    key: 'testResultSelector',
    get: () => {
      const usernameCookie = Cookies.get('username', { domain: config.cookieDomain });
      let user = JSON.parse(localStorage.getItem('user'));
      let resultFromLocalStorage;
      if (user && usernameCookie in user) {
        resultFromLocalStorage = user[usernameCookie]?.result;
      }
      console.log(resultFromLocalStorage);
      if (resultFromLocalStorage) {
        return resultFromLocalStorage;
      }
      return {};
    }
  }),
});

export const isAuthorized = atom({
  key: 'isAuthorized',
  default: false,
})

export const isPaid = atom({
  key: 'isPaid',
  default: false
})

export const isReviewSubmitted = atom({
  key: 'isReviewSubmitted',
  default: false
})

export const isLoginLoader = atom({
  key: 'isLoginLoader',
  default: false
})

export const languageSelectionModal = atom({
  key: 'languageSelectionModal',
  default: false
})

export const courseAmount = atom({
  key: 'courseAmount',
  default: 1599
})

export const redeemedAmount = atom({
  key: 'redeemedAmount',
  default: 1599
})

export const couponCode = atom({
  key: 'couponCode',
  default: null
})

export const questionsArray = atom({
  key: 'questionsArray',
  default: selector({
    key: 'questionsArraySelector',
    get: () => {
      let questions = JSON.parse(localStorage.getItem('questions'));
      if (questions) return questions;
      return [];
    }
  })
})