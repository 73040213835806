import React from "react";
import { Link } from "react-router-dom";
import pathImg from "../../Assets/test-card-path.png";
import doctorImg from "../../Assets/doctor-3d.png";
import nurseImg from "../../Assets/nurse-3d.png";

const TestCard = () => {
  return (
    <div className="px-11 py-8 bg-[radial-gradient(80.93%_346.06%_at_75%_130%,#214CCC_15%,#549BFF_100%)] relative h-[400px] sm-max:h-[450px] overflow-hidden mt-52 rounded-2xl">
      <div className="flex flex-col gap-6 max-w-[40%] lg:lg-small:max-w-[50%] md:md-max:max-w-[80%] sm-max:max-w-full sm-max:text-center sm-max:justify-between sm-max:h-full relative z-40">
        <div className="flex flex-col gap-6">
          <h3 className="text-white text-3xl font-semibold">
            Answer me questions to know your personality
          </h3>
          <p className="text-white text-sm">
            Start monitoring your API endpoints and your vital site
            transactions.
          </p>
        </div>
        <Link to="/home">
          <button className="px-4 py-2 bg-white text-black rounded-md w-max">
            Start the Test
          </button>
        </Link>
      </div>

      <img
        src={pathImg}
        alt="path-img"
        className="absolute bottom-0 left-[15%] h-[400px] text-black mix-blend-multiply"
      />

      <img
        src={nurseImg}
        alt="nurse-img"
        className="absolute bottom-0 left-10 z-20 sm-max:hidden"
        width={200}
      />
      <img
        src={doctorImg}
        alt="doctor-img"
        className="absolute bottom-0 right-10 z-20 sm-max:right-0"
        width={200}
      />
    </div>
  );
};

export default TestCard;
